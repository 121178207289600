import classes from "./WelcomeMessage.module.css";
import apple from "./../../../assets/images/apple.png";
import android from "./../../../assets/images/android.png";
import information from "./../../../assets/images/information_project.png";
import { useTranslation } from "react-i18next";
import {
  androidAppLink,
  informationLink,
  informationLinkEN,
  iosAppLink,
  welcomeMessageContent,
} from "../../../../SMARTCITY/hardCodeData/DATA";
import { useContext } from "react";
import LanguageContext from "../../../hooks/language-context";
import { useHistory } from "react-router-dom";
import SvgTermsofuse2 from "../../../assets/icons/Termsofuse2";
import sponsor from "./../../../../SMARTCITY/assets/images/sponsor.png";

const WelcomeMessage = () => {
  const { t } = useTranslation();
  const languageCtx = useContext(LanguageContext);
  const history = useHistory();
  return (
    <div className={classes.WelcomeContainer}>
      <div className={classes.welcome_placeholder}>
        <p>{t(welcomeMessageContent[0])}</p>
        <p>{t(welcomeMessageContent[1])}</p>
      </div>
      <div className={classes.infoContainer}>
        {/* epsa */}
        <div className={classes.espaLogo}>
          <a
            href="https://nskoufas.gr/olokliromeni-platforma-ilektronikon-ypiresion/"
            target="_blank"
          >
            <img width={300} src={sponsor} alt="sponsor logo" />
          </a>
          <span className={classes.espaText}>
            Με τη συγχρηματοδότηση της Ελλάδας και της Ευρωπαϊκής Ένωσης
          </span>
        </div>
        {/* terms of use */}
        <div
          className={`${classes.infoItem} ${classes.hide}`}
          onClick={() => history.push("/terms-of-use")}
        >
          <SvgTermsofuse2 width={35} />
          {languageCtx.languageCode === "el" ? (
            <p>{t("Δήλωση Προσβασιμότητας")}</p>
          ) : (
            <p>{t("Δήλωση Προσβασιμότητας")}</p>
          )}
        </div>
        {/* info */}
        {/* <div
          className={`${classes.infoItem} ${classes.hide}`}
          onClick={() =>
            window.open(
              languageCtx.languageCode === "en"
                ? informationLinkEN
                : informationLink
            )
          }
        >
          <img
            src={information}
            className={classes.inf_normal}
            alt={"information-application"}
            width={35}
          />
          <p>{t(" Πληροφορίες για το έργο")}</p>
        </div> */}
        {/* mobile */}
        <div className={classes.hide}>
          <img
            src={apple}
            alt={"apple-application"}
            width={150}
            onClick={() => window.open(iosAppLink)}
          />
          <img
            src={android}
            alt={"android-application"}
            width={150}
            onClick={() => window.open(androidAppLink)}
          />
        </div>
      </div>
    </div>
  );
};

export default WelcomeMessage;
