import {useEffect, useContext} from "react";
import {useStore} from "../../TEMPLATE/hooks/store";
import {getAllSensors, getAllTypes} from "../../TEMPLATE/services/feed";
import {
  feedBins,
  feedEnvironment_Meteo,
  feedFleeto,
  feedMeshlium,
  feedWifi,
} from "../../TEMPLATE/services/metrics";
import {
  allTypesUrl,
  allSensorsUrl,
  meteoURL,
  fleetoUrl,
  binsUrl,
  wifiUrl,
  hamURL
} from "../hardCodeData/DATA";
import LanguageContext from "./../../TEMPLATE/hooks/language-context";

const DynamicData = () => {
  const dispatch = useStore(false)[1];

  const controller = new AbortController();
  const {signal} = controller;

  const languageCtx = useContext(LanguageContext);

  useEffect(() => {
    const feed_types_call = async () => {
      const data = await getAllTypes(
        `${allTypesUrl}&lang=${languageCtx.languageCode}`,
        signal
      );
      dispatch("INITIALIZE_TYPES", data);
    };
    const feed_sensors = async () => {
      const data = await getAllSensors(
        `${allSensorsUrl}&lang=${languageCtx.languageCode}`,
        signal
      );
      dispatch("INITIALIZE_SENSORS", data);
      getMetrics().catch((error) => console.warn(error));
    };

    feed_types_call().catch((error) => console.warn(error));
    feed_sensors().catch((error) => console.warn(error));
    return () => controller.abort();
  }, [languageCtx.languageCode]);

  const getMetrics = async () => {


    // feedEnvironment_Meteo(enviURL, signal)
    //   .then((data) => dispatch("ADD_METRICS", data))
    //   .catch((error) => console.warn(error));

    // feedMeshlium(meshURL, signal)
    //   .then((data) => dispatch("ADD_METRICS", data))
    //   .catch((error) => console.warn(error));



    feedEnvironment_Meteo(meteoURL, signal)
      .then((data) => dispatch("ADD_METRICS", data))
      .catch((error) => console.warn(error));



    // feedPOIS(poiURL, signal)
    //   .then((data) => dispatch("ADD_SENSORS", data))
    //   .catch((error) => console.warn(error));



    feedFleeto(fleetoUrl, signal)
      .then((data) => dispatch("ADD_SENSORS", data))
      .catch((error) => console.warn(error));



    feedBins(binsUrl, signal)
      .then((data) => dispatch("ADD_SENSORS", data))
      .catch((error) => console.warn(error));


    feedEnvironment_Meteo(hamURL, signal)
      .then((data) => dispatch("ADD_METRICS", data))
      .catch((error) => console.warn(error));

    // feedHydro(hydroUrl, signal)
    //   .then((data) => dispatch("ADD_SENSORS", data))
    //   .catch((error) => console.warn(error));


    feedWifi(wifiUrl, signal)
      .then((data) => dispatch("ADD_METRICS", data))
      .catch((error) => console.warn(error));
  };

  return null;
};

export default DynamicData;
