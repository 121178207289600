import React, { useEffect, useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import classes from "./SubsystemPopupMenuVars.module.css";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingTop: "8px",
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

const SubsystemPopupMenuVars = ({
  filtersVars,
  varsList,
  setVars,
  clearVars,
  close,
}) => {
  const classesStyle = useStyles();
  const [list, setList] = useState([]);
  const [clearVisible, setClearVisible] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (filtersVars.length > 0) {
      const tempList = varsList.map((item) => {
        return {
          var: item,
          isActive: !!filtersVars.includes(item),
        };
      });
      setList(tempList);
      setClearVisible(true);
    } else {
      const tempList = varsList.map((item) => ({
        var: item,
        isActive: false,
      }));
      setList(tempList);
      setClearVisible(false);
    }
  }, [filtersVars]);

  const handleChange = (event) => {
    console.log(event.target.value);

    setVars(list[event.target.value].var);
  };

  const clearFilters = () => {
    clearVars();
  };

  return (
    <Fragment>
      <div className={classesStyle.root}>
        <FormControl component="fieldset" className={classesStyle.formControl}>
          <FormLabel component="legend">{t("Επιλέξτε μεταβλήτες")}</FormLabel>
          <FormGroup>
            {list.slice(0, Math.ceil(list.length / 2)).map((item, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={item.isActive}
                    onChange={handleChange}
                    name={item.var}
                    value={index}
                  />
                }
                label={item.var}
              />
            ))}
          </FormGroup>
        </FormControl>
        <FormControl component="fieldset" className={classesStyle.formControl}>
          <FormLabel component="legend" style={{ visibility: "hidden" }}>
            {t("Επιλέξτε μεταβλήτες")}
          </FormLabel>
          <FormGroup>
            {list
              .slice(Math.ceil(list.length / 2), list.length)
              .map((item, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={item.isActive}
                      onChange={handleChange}
                      name={item.var}
                      value={index + parseInt(Math.ceil(list.length / 2))}
                    />
                  }
                  label={item.var}
                />
              ))}
            <FormHelperText>{t("Από το υπάρχων υποσύστημα")}</FormHelperText>
          </FormGroup>
        </FormControl>
      </div>
      <div className={classes.btns_action}>
        {clearVisible && (
          <Button
            variant="contained"
            color="secondary"
            onClick={clearFilters}
            style={{ margin: "5px", width: "111.25px" }}
          >
            {t("Απαλοιφη")}
          </Button>
        )}
        <Button
          variant="contained"
          color="secondary"
          onClick={close}
          style={{ margin: "5px", width: "111.25px" }}
        >
          {t("Εφαρμογη")}
        </Button>
      </div>
    </Fragment>
  );
};
export default SubsystemPopupMenuVars;
