import classes from "./SmallCharts.module.css";
import { useContext, useEffect, useState } from "react";
import ChartLine from "../ChartLine";
import { useLocation } from "react-router-dom";
import { LoadingRise } from "../../UI/spinners/LoadingSpinners";

import { specificBinChart } from "../../../../SMARTCITY/hardCodeData/DATA";
import { useTranslation } from "react-i18next";
import LanguageContext from "../../../hooks/language-context";

const initStatistcData = {
  description: "Πληρότητα",
  name: "Κάδοι",
  unit: "%",
  details: [],
};
const SmallCharts = (props) => {
  const [activeCategory, setActiveCategory] = useState(1);
  const [statisticData, setStatisticData] = useState(initStatistcData);
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();

  const { t } = useTranslation();
  const languageCtx = useContext(LanguageContext);

  const controller = new AbortController();
  const { signal } = controller;

  const selectCategory = (category) => {
    setActiveCategory(category);
  };

  useEffect(() => {
    const sensorId = new URLSearchParams(search).get("sensorId");

    if (sensorId)
      getSensorStats(sensorId).catch((error) => console.warn(error));
  }, [search, activeCategory, languageCtx]);

  const getSensorStats = async (sensorId) => {
    setStatisticData([]);
    setLoading(true);
    const response = await fetch(`${specificBinChart}&id=${sensorId}`, {
      signal,
    }).catch((error) => console.warn(error));
    if (response === undefined || !response.ok) {
      setLoading(false);
      return;
    }

    const data = await response.json();
    let avg = 0;
    let min = 100;
    let max = 0;

    for (let i = 0; i < data.length; i++) {
      if (Number(data[i].value) > max) max = Number(data[i].value);
      if (Number(data[i].value) < min) min = Number(data[i].value);
      avg += Number(data[i].value);
    }

    setStatisticData({
      ...initStatistcData,
      min,
      max,
      avg: avg / data.length,
      details: data.map((item) => ({
        date_insert: item.labelDate,
        datetime: item.labelDate.split("-").reverse().join("/"),
        val: item.value,
      })),
    });
    setLoading(false);
  };

  return (
    <div className={classes.small_statistics_container}>
      <div>
        <button
          type={"button"}
          className={
            activeCategory === 1 ? classes.active : classes.button_switch
          }
          onClick={() => selectCategory(1)}
        >
          {t("Μηνιαία")}
        </button>
        {/*<button*/}
        {/*  type={"button"}*/}
        {/*  className={*/}
        {/*    activeCategory === 3 ? classes.active : classes.button_switch*/}
        {/*  }*/}
        {/*  onClick={() => selectCategory(3)}*/}
        {/*>*/}
        {/*  {t("Εβδομαδιαία")}*/}
        {/*</button>*/}
      </div>

      <ChartLine size={"small"} data={statisticData} type={3} />

      {/*{loading && (*/}
      {/*  <div style={{ minHeight: "calc(369px*3)" }}>*/}
      {/*    <div style={{ minHeight: "369px", position: "relative" }}>*/}
      {/*      <LoadingRise />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};
export default SmallCharts;
