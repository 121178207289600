import classes from "./RightPanelContent.module.css";
import BoxTitle from "../../box/title/BoxTitle";
import BoxEntity from "../../box/entity/BoxEntity";
import { useEffect, useState, useRef } from "react";
import { useStore } from "../../../hooks/store";
import parse from "html-react-parser";
import CustomSlider from "../../customSlider/CustomSlider";
import { SRLWrapper } from "simple-react-lightbox";
import SimpleReactLightbox from "simple-react-lightbox";
import { Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SmallCharts from "../../charts/SmallCharts/SmallCharts";
import SvgRecycleBinEmpty from "../../../assets/icons/RecycleBinEmpty";
import SvgRecycleBinLow from "../../../assets/icons/RecycleBinLow";
import SvgRecycleBinFull from "../../../assets/icons/RecycleBinFull";

const options = {
  settings: {
    overlayColor: "rgb(104, 104, 104)",
    autoplaySpeed: 1500,
    transitionSpeed: 900,
  },
  buttons: {
    backgroundColor: "black",
    iconColor: "white",
  },
  caption: {
    captionColor: "white",
    captionFontFamily: "Raleway, sans-serif",
    captionFontWeight: "300",
    captionTextTransform: "uppercase",
  },
  progressBar: {
    backgroundColor: "#F2F2F2",
    fillColor: "#000000",
    height: "3px",
    showProgressBar: true,
  },
};

const RightPanelContent = () => {
  const [data, setData] = useState({ metrics: [], title: "" });
  const [loadingData, setLoadingData] = useState(false);
  const state = useStore()[0];
  const typeStyleVertical = useRef(null);
  const colorIconTitle = useRef(null);
  const iconTitleRef = useRef(null);

  const { search } = useLocation();

  useEffect(() => {
    if (search !== "") {
      const currTypeURL = new URLSearchParams(search).get("typeId");
      const currSensorURL = new URLSearchParams(search).get("sensorId");

      const tempTypeIdActive = state.types.filter(
        (item) => item.id === currTypeURL
      );

      if (tempTypeIdActive.length > 0 && state.sensors.length > 0) {
        iconTitleRef.current = tempTypeIdActive[0].icon;
        colorIconTitle.current = {
          backgroundColor: tempTypeIdActive[0].color,
          minWidth: "50px",
          maxWidth: "50px",
          minHeight: "50px",
          maxHeight: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "12px",
        };

        setLoadingData(true);

        if (currSensorURL) {
          const sensor = state.sensors.filter(
            (item) =>
              item.id.toString() === currSensorURL &&
              item.typeId.toString() === currTypeURL
          );

          if (sensor.length > 0) {
            if (sensor[0].typeId.toString() === "7") {
              setData({
                historic: {
                  category: sensor[0].categoryName,
                  image: sensor[0].thumbnail,
                  content: sensor[0].content,
                  images: sensor[0].images,
                  // images: [
                  //   "https://alexandroupoli.repository.gr/files/asset/b2d3f6e7dc5dd2435bdb3f21127ecf60221e71b8.jpg",
                  //   state.sensors[i].thumbnail,
                  // ],
                },
                title: sensor[0].title,
              });
              typeStyleVertical.current = "POIS";
            } else {
              setData({
                metrics: sensor[0].metrics,
                title: sensor[0].title,
              });
              typeStyleVertical.current = "Metrics";
            }
          }
        } else {
          setData({ metrics: [], title: "" });
        }
      }
    }
  }, [search, state.types, state.sensors]);

  useEffect(() => {
    if (data.title !== "") {
      setLoadingData(false);
    }
  }, [data]);

  const Metrics =
    data.metrics &&
    data.metrics
      .filter((item) => item.value)
      .map((item, index) => (
        <BoxEntity
          key={index}
          icon={item.icon_large}
          content={item.content}
          value={item.value}
          className={classes.gradient_border}
          styleValue={{ fontWeight: "400" }}
        />
      ));

  const description = data.description && (
    <div>
      <h4 style={{ marginBlock: "30px 10px" }}>Περιγραφή</h4>
      <p style={{ fontSize: 14 }}>{data.description}</p>
    </div>
  );

  const POIS = data.historic && (
    <Fragment>
      <SimpleReactLightbox>
        <SRLWrapper options={options}>
          {data.historic.image && (
            <img
              src={data.historic.image}
              alt="thumbnail"
              width="100%"
              style={{ cursor: "pointer" }}
            />
          )}
        </SRLWrapper>
      </SimpleReactLightbox>

      {data.historic.category && (
        <p style={{ fontSize: "14px" }}>
          Κατηγορία: <i>{data.historic.category}</i>
        </p>
      )}
      {data.historic.content && (
        <div style={{ marginTop: "20px", textAlign: "justify" }}>
          {parse(data.historic.content)}
        </div>
      )}

      {data.historic.images.length > 0 && (
        <div className={classes.slider_placeholder}>
          <p className={classes.more_photos_descr}>
            <i>Επιπλέον φωτογραφικό υλικό:</i>
          </p>
          <SimpleReactLightbox>
            <SRLWrapper options={options}>
              <CustomSlider
                settings={{
                  infinite: false,
                }}
              >
                {data.historic.images.map((image, index) => (
                  <div key={index} className={classes.image_placeholder}>
                    <img
                      src={image}
                      alt={data.title}
                      width="100%"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                ))}
              </CustomSlider>
            </SRLWrapper>
          </SimpleReactLightbox>
        </div>
      )}
    </Fragment>
  );
  return (
    <div className={classes.info_container}>
      <div className={classes.sticky_title}>
        <BoxTitle
          icon={iconTitleRef.current}
          title={data.title}
          styleIcon={colorIconTitle.current}
          className={[classes.info_title, classes.height_unset].join(" ")}
        />
        <hr className={classes.gradient_line_style} />
      </div>

      {!loadingData && typeStyleVertical.current === "Metrics" && Metrics}
      {!loadingData && typeStyleVertical.current === "POIS" && POIS}

      {!loadingData && data?.description && description}

      {!loadingData && new URLSearchParams(search).get("typeId") === "9" && (
        <>
          <div style={{ marginBlock: 40 }}>
            {data.metrics
              .filter((item) => item.content === "Ποσοστό πληρότητας κάδου")
              .map((item) => (
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      color: "black",
                      fontSize: "21px",
                    }}
                  >
                    <strong>{item.value}</strong>
                  </span>

                  {item.value > "70%" ? (
                    <SvgRecycleBinFull width={100} height={101} />
                  ) : item.value > "30%" ? (
                    <SvgRecycleBinLow width={100} height={101} />
                  ) : (
                    <SvgRecycleBinEmpty width={100} height={101} />
                  )}
                </div>
              ))}
          </div>
        </>
      )}

      {!loadingData && new URLSearchParams(search).get("typeId") === "9" && (
        <SmallCharts />
      )}
      {/*{!loadingData && new URLSearchParams(search).get("typeId") === "1" && (*/}
      {/*  <SmallCharts loop={["1", "9", "11"]} />*/}
      {/*)}*/}
    </div>
  );
};

export default RightPanelContent;
