import classes from "./Weather.module.css";
import { Fragment, useContext, useEffect, useState } from "react";
import BoxTitle from "../../box/title/BoxTitle";

import { LoadingDot } from "../../UI/spinners/LoadingSpinners";
import { useTranslation } from "react-i18next";

import { baseUrl, weatherUrl } from "../../../../SMARTCITY/hardCodeData/DATA";
import LanguageContext from "../../../hooks/language-context";

const Weather = () => {
  const controller = new AbortController();
  const { signal } = controller;
  const { t } = useTranslation();
  const languageCtx = useContext(LanguageContext);
  const [weatherData, setWeatherData] = useState(null);

  useEffect(() => {
    const getWeather = async () => {
      const req = await fetch(
        `${weatherUrl}&lang=${languageCtx.languageCode}`,
        {
          signal,
        }
      ).catch((error) => console.warn(error));
      if (req === undefined || !req.ok) return;
      const response = await req.json();
      setWeatherData(response[0]);
      console.log(response[0]);
    };
    getWeather().catch((error) => console.warn(error));
  }, [languageCtx.languageCode]);

  return (
    <Fragment>
      <BoxTitle
        className={classes.weatherTitle}
        title={t("ΚΑΙΡΟΣ")}
        infoContext={"Δείτε τη γενική πρόγνωση καιρού και μέγιστης θερμοκρασίας για σήμερα και για αύριο"}
        element={
          <i
            id="draggable"
            className="im im-icon-Double-Tap"
            style={{
              fontSize: "32px",
              minWidth: "32px",
              color: "black",
              cursor: "pointer",
            }}
          />
        }
      />

      {weatherData && (
        <div className={classes.weatherGridContainer}>
          <div className={classes.squareBox}>
            <img
              src={baseUrl + weatherData.curr_icon}
              alt="weather_icon_totday"
              width="75px"
            />
            <p className={classes.descriptionContent}>
              {weatherData.curr_description}
            </p>
          </div>
          <div className={classes.squareBox}>
            <p className={classes.tempBox}>
              {weatherData.curr_val} <sup>o</sup>C
            </p>
            <p className={classes.descriptionContent}>
              {weatherData.curr_title}
            </p>
          </div>
          <div className={classes.squareBox}>
            <img
              src={baseUrl + weatherData.next_icon}
              alt="weather_icon_tommorw"
              width="75px"
            />
            <p className={classes.descriptionContent}>
              {weatherData.next_description}
            </p>
          </div>
          <div className={classes.squareBox}>
            <p className={classes.tempBox}>
              {weatherData.next_val} <sup>o</sup>C
            </p>
            <p className={classes.descriptionContent}>
              {weatherData.next_title}
            </p>
          </div>
        </div>
      )}
      {!weatherData && <LoadingDot />}
    </Fragment>
  );
};

export default Weather;
