import { Fragment } from "react";
import BoxTitle from "../../../TEMPLATE/components/box/title/BoxTitle";
import { diavLink } from "../../hardCodeData/DATA";

const CustomCategoryDiav = () => {
  return (
    <Fragment>
      <BoxTitle
        icon={"im im-icon-Speach-BubbleDialog"}
        title={"ΔΗΜΟΣΙΕΣ ΔΙΑΒΟΥΛΕΥΣΕΙΣ"}
        externalLink={diavLink}
        infoContext={"Παρακολουθήστε και συμμετέχετε ενεργά στη δημόσια διαβούλευση για τη διαδικασία λήψης αποφάσεων κατά τον σχεδιασμό και την εφαρμογή έργων και πολιτικών στο Δήμο μας"}
        element={
          <i
            id="draggable"
            className="im im-icon-Double-Tap"
            style={{ fontSize: "32px", minWidth: "32px", cursor: "pointer" }}
          />
        }
      />
      <p
        style={{
          marginTop: 10,
          color: "white",
          padding: 10,
          textAlign: "justify",
          lineHeight: "1.5",
        }}
      >
        Ισότιμη συμμετοχή σε έναν ευρύτερο δημόσιο διάλογο
      </p>
      <button
        type={"button"}
        style={{
          width: 180,
          height: 60,
          marginLeft: "calc((100% - 180px ) /2)",
          marginTop: 20,
          borderRadius: "30px",
          border: "none",
          cursor: "pointer",
        }}
        onClick={() => window.open(diavLink, "_blank")}
      >
        <span style={{ fontWeight: "700", fontSize: "16px" }}>Είσοδος</span>
      </button>
    </Fragment>
  );
};
export default CustomCategoryDiav;
