import classes from "./HAM.module.css";
import { Fragment, useEffect, useState } from "react";
import { LoadingDot } from "../../UI/spinners/LoadingSpinners";
import BoxTitle from "../../box/title/BoxTitle";
import CustomSlider from "../../customSlider/CustomSlider";
import BoxEntity from "../../box/entity/BoxEntity";
import BoxSubTitle from "../../box/subTitle/BoxSubTitle";

const HAM = ({
  fetchDataLink,
  title,
  typeId,
  showSubTitle,
  maxEntities,
  mediaPrefix,
}) => {
  const [data, setData] = useState(null);

  const controller = new AbortController();
  const { signal } = controller;

  useEffect(() => {
    const fetchData = async () => {
      const request = await fetch(fetchDataLink, { signal });
      if (!request.ok) return;
      const fetchedData = await request.json();

      let tempItems = [];
      const finallyGroup = [];

      fetchedData.group.map((item, index) => {
        tempItems.push(item);
        if (
          tempItems.length === maxEntities ||
          index === fetchedData.group.length - 1
        ) {
          const currentItems = tempItems;
          tempItems = [];
          finallyGroup.push(currentItems);
        }
      });

      setData({ ...fetchedData, group: finallyGroup });
    };
    fetchData().catch((error) => console.error(error));
    return () => controller.abort();
  }, []);

  return (
    <Fragment>
      <BoxTitle
        icon={data?.sensortype_icon || ``}
        title={title}
        link={`/map?typeId=${typeId}`}
        infoContext={"Δείτε την κατανάλωση ρεύματος των εγκαταστάσεων του Δήμου μας"}
        element={
          <i
            id="draggable"
            className="im im-icon-Double-Tap"
            style={{ fontSize: "32px", minWidth: "32px", cursor: "pointer" }}
          />
        }
        id={typeId}
      />

      {data && data.group.length > 0 && (
        <CustomSlider>
          {data.group.map((item, index) => (
            <Fragment key={index}>
              {showSubTitle && <BoxSubTitle>{item.group_name}</BoxSubTitle>}
              <div>
                {item.map((datum, index) => (
                  <>
                    <BoxEntity
                      key={index}
                      icon={mediaPrefix + datum.sensorvar_icon}
                      content={datum.group_name}
                      value={`${datum.group_val}\xa0${datum.sensorvar_unit}`}
                    />
                  </>
                ))}
              </div>
            </Fragment>
          ))}
        </CustomSlider>
      )}
      {(!data || data.group.length === 0) && <LoadingDot />}
    </Fragment>
  );
};

export default HAM;
