const removeEntityNoIcon = (data) => {
  let newData = [];

  if (data.length > 0) {
    newData = data.filter((item) => item.icon_large && item.valid === 1);
  }

  return newData;
};

export default removeEntityNoIcon;
